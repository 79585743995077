<!--
 * @Description: 个人中心
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-06-22 15:15:44
 * @LastEditors: Seven
 * @LastEditTime: 2021-09-02 11:01:02
-->
<template>
  <div style="background: #f5f5fa">
    <div class="user-center">
      <hq-title>个人信息</hq-title>
      <div class="user-center-body">
        <el-row :gutter="20">
          <el-col :span="3">
            <p class="filed avatar-text">头像：</p>
          </el-col>
          <el-col :span="18">
            <div class="avatar-wrap">
              <div class="avatar" @click="openAvatar">
                <img
                  v-if="userInfo.avatar"
                  :src="userInfo.avatar"
                  alt="选择头像"
                  @error="onImgError"
                />
                <!-- <img v-else src="@/assets/image/lake.jpg" /> -->
                <div v-else>点击上传头像</div>
              </div>
              <i class="iconfont iconCamerafill"></i>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="3">
            <p class="filed">用户姓名：</p>
          </el-col>
          <el-col :span="18">
            <p>{{ userInfo.userName }}</p>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="3">
            <p class="filed">用户手机：</p>
          </el-col>
          <el-col :span="18">
            <p>{{ userInfo.phone }}</p>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="3">
            <p class="filed">组织机构：</p>
          </el-col>
          <el-col :span="18">
            <p>{{ userInfo.deptName }}</p>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="3">
            <p class="filed">角色：</p>
          </el-col>
          <el-col :span="18">
            <span style="margin-right: 10px;" v-for="(item, index) in userInfo.roleList">
              {{ item.roleName }}
            </span>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="3">
            <p class="filed">登录密码：</p>
          </el-col>
          <el-col :span="18">
            <el-button type="text" @click="passwordShow = true"
              >修改密码</el-button
            >
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="3">
            <p class="filed">零一裂变：</p>
          </el-col>
          <el-col :span="18">
            <span class="lingyiAvatar"> <img :src="userInfo.lingyiAvatar" alt=""> </span>&nbsp;
            <span>{{ userInfo.lingyiUserName }}</span>&nbsp;&nbsp;&nbsp;
            <span style="cursor:pointer; color: #ddaa00" @click="showBInglingyi">去绑定</span>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="3">
            <p class="filed">零一裂变ID：</p>
          </el-col>
          <el-col :span="18">
            <p>{{ userInfo.lingyiUserId }}</p>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="3">
            <p class="filed">呼叫中心坐席：</p>
          </el-col>
          <el-col :span="18">
            <el-radio v-model="radioStatus" @change="changeRadio(1)" label="1">SIP模式</el-radio>
            <el-radio v-model="radioStatus" @change="changeRadio(2)" label="2">双呼模式</el-radio>
          </el-col>
          <el-col :span="18" style="marginTop: 20px" v-if="radioStatus == '1'">
            <p style="display:inline-block">{{ userInfo.con}}{{userInfo.con?'/':''}}{{userInfo.cnoPswd}}</p>&nbsp;&nbsp;&nbsp;
            <span style="cursor:pointer; color: #ddaa00" @click="showzuoxiClick">去绑定</span>&nbsp;&nbsp;&nbsp;
            <span style="cursor:pointer; color: #ddaa00" @click="showzuoxicancel">解绑</span>
          </el-col>
          <el-col :span="18" v-if="radioStatus == '1'">
            <span style="cursor:pointer; color:#337AFF " @click="showzuoxijump">IP话机设置方法</span>
          </el-col>
          <el-col :span="18" style="marginTop: 20px" v-if="radioStatus == '2' && !btnshowtel">
            <el-col :span="13">
              <v-text-field
                v-model="bindTel"
                label="手机号"
                hide-details="auto"
                outlined
                :dense="true"
                :height="32"
              ></v-text-field>
            </el-col>
          </el-col>
          <el-col :span="10" style="paddingLeft:156px" v-if="radioStatus == '2' && !btnshowtel">
            <el-col :span="24">
              <v-text-field
                v-model="verCode"
                label="验证码"
                hide-details="auto"
                outlined
                :dense="true"
                :height="32"
              ></v-text-field>
            </el-col>
          </el-col>
              <el-col :span="2" style="marginTop: 4px" v-if="radioStatus == '2' && !btnshowtel">
                <el-button @click="verCodeClick" type="primary" :disabled="showDisabled">{{phoneTipMsg}}</el-button>
              </el-col>
          <el-col :span="18" style="marginTop: 10px; paddingLeft:156px" v-if="radioStatus == '2' && !btnshowtel">
            <el-col :span="3">
              <el-button @click="bindClick" type="primary">绑定</el-button>
            </el-col>
          </el-col>
           <div style="position: absolute;left:156px;top:42px"  v-if="radioStatus == '2' && btnshowtel">
             {{bindTel}} &nbsp;&nbsp;&nbsp;&nbsp; 
             <el-popconfirm
                confirm-button-text='确定'
                cancel-button-text='取消'
                icon="el-icon-info"
                icon-color="red"
                title="您确定解绑吗?"
                @confirm="jbBtlclick"
              >
                <span slot="reference" style="cursor:pointer; color: #ddaa00">解绑</span>
              </el-popconfirm>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <span style="cursor:pointer; color: #ddaa00" @click="replacebing">更换绑定</span>
            </div>
        </el-row>
        <el-row :gutter="20" style="margin-top: 30px;">
          <el-col :span="3">
            <p class="filed">微信绑定：</p>
          </el-col>
          <el-col :span="18">
            <span v-if="userInfo.hasWechat">已绑定</span>
            <span v-if="!userInfo.hasWechat" style="cursor:pointer; color: #ddaa00" @click="showBindWX">去绑定</span>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 绑定微信 -->
    <HqModal :visible="isShowBindWX" title="扫码绑定微信" width="830" @on-cancel="isShowBindWX = false">
      <div id="WXlogin_container"></div>
      <div slot="footer">
        <el-button @click="isShowBindWX = false">关闭</el-button>
      </div>
    </HqModal>

    <!-- 更换绑定弹窗 -->
    <HqModal :visible="showreplacebing" title="更换绑定" width="830" @on-cancel="cancelreplace">
      <el-row style="width: 94%;padding-left:24px;">
        <el-col :span="12">
          <span style="MarginBottom:10px">
            {{bindTel}}
          </span>
        </el-col>
        <br />
        <el-col :span="24">
          <v-text-field
            v-model="bindTelnew"
            label="请输入新手机号"
            hide-details="auto"
            outlined
            :dense="true"
            :height="32"
            style="padding-top: 10px;"
          ></v-text-field>
        </el-col>
      </el-row>
      <div slot="footer">
        <el-button @click="cancelreplace">取消</el-button>
        <el-button @click="savereplace" type="primary">确认</el-button>
      </div>
    </HqModal>
    <!-- 呼叫坐席 -->
    <HqModal :visible="showzuoximodal" title="绑定呼叫坐席" width="830" @on-cancel="cancelzuoxi">
      <el-row style="width: 94%;padding-left:24px;">
        <el-col :span="10">
          <v-text-field
              v-model="workerName"
              label="姓名"
              hide-details="auto"
              outlined
              :dense="true"
              :height="32"
            ></v-text-field>
        </el-col>
        <el-col :span="12">
          <el-button class="searchBtn" @click="searchzuoxi">查询</el-button>
          <el-button class="searchBtn" @click="resetFnzuoxi">重置</el-button>
          <span style="margin-left: 20px;cursor:pointer; color: #337AFF" @click="zuoximodaljump">校长解绑呼叫坐席指引</span>
        </el-col>
      </el-row>
      <span style="margin-left: 20px;color: #337AFF ">呼叫坐席由校长或者省区负责人与呼叫中心购买所得，如校区有购买呼叫坐席，请联系校长索取坐席信息</span>
      <el-row style="width: 94%; margin: 0 auto">
        <el-col>
          <el-table
          highlight-current-row
          ref="multipleTable"
          :data="zuoxiList"
          tooltip-effect="dark"
          style="width: 100%"
          border
          stripe
          size="mini"
          v-loading="listLoading"
          element-loading-text="拼命加载中"
          :row-style="{ height: '40px' }"
          :cell-style="{ padding: '0px' }"
          :header-cell-style="{ background: '#e5f2ff' }"
          @row-click="clickRowzuoxi"
        >
          <el-table-column prop="name" label="姓名" align="center"></el-table-column>
          <el-table-column prop="workerAccount" label="账号" align="center"></el-table-column>
          <el-table-column prop="cno" label="分机号" align="center"></el-table-column>
          <el-table-column prop="deptName" label="部门" align="center"></el-table-column>
        </el-table>
        <div class="page_test_bottom">
          <el-pagination
            background
            @size-change="sizeChangezuoxi"
            @current-change="currentChangezuoxi"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="pageParamszuoxi.pageNum"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageParamszuoxi.pageSize"
            :total="pageParamszuoxi.total"
          />
        </div>
        </el-col>
      </el-row>

      <div slot="footer">
        <el-button @click="cancelzuoxi">取消</el-button>
        <el-button @click="savezuoxi" type="primary">确认</el-button>
      </div>
    </HqModal>

    <!-- 绑定零一裂变 -->
    <HqModal :visible="shoLingyimodal" title="绑定零一裂变用户" width="830" @on-cancel="cancellingyi">
      <!-- <v-autocomplete
        v-model="transferForm.lingyiUserId"
        :items="LingyiList"
        :menu-props="{ bottom: true, offsetY: true }"
        label="零一裂变用户"
        item-text="nickName"
        item-value="userId"
        outlined
        :dense="true"
        :height="32"
        :clearable="true"
        clear-icon="$clear"
        hide-details="auto"
      >
        <template v-slot:item="slotProps">
          <img class="selectImg" :src="slotProps.item.avatar" alt="">
          {{slotProps.item.nickName}}
        </template>
      </v-autocomplete> -->
      <el-row style="width: 94%;padding-left:24px;">
        <el-col :span="10">
          <v-text-field
              v-model="nickName"
              label="用户名"
              hide-details="auto"
              outlined
              :dense="true"
              :height="32"
            ></v-text-field>
        </el-col>
        <el-col :span="12">
          <el-button class="searchBtn" @click="searchUsernc">查询</el-button>
          <el-button class="searchBtn" @click="resetFn">重置</el-button>
        </el-col>
      </el-row>
      <el-row style="width: 94%; margin: 0 auto">
        <el-col>
          <el-table
          highlight-current-row
          ref="multipleTable"
          :data="LingyiList"
          tooltip-effect="dark"
          style="width: 100%"
          border
          stripe
          size="mini"
          v-loading="listLoading"
          element-loading-text="拼命加载中"
          :row-style="{ height: '20px' }"
          :cell-style="{ padding: '0px' }"
          :header-cell-style="{ background: '#e5f2ff' }"
          @row-click="clickRownc"
        >
          <el-table-column prop="avatar" label="头像" align="center">
            <template slot-scope="scope">
              <img class="selectImg" :src="scope.row.avatar" alt="">
            </template>
          </el-table-column>
          <el-table-column prop="nickName" label="用户名" align="center"></el-table-column>
        </el-table>
        <div class="page_test_bottom">
          <el-pagination
            background
            @size-change="sizeChange"
            @current-change="currentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="pageParams.pageNum"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageParams.pageSize"
            :total="total"
          />
        </div>
        </el-col>
      </el-row>

      <div slot="footer">
        <el-button @click="cancellingyi">取消</el-button>
        <el-button @click="savelingyi" type="primary">确认</el-button>
      </div>
    </HqModal>
    <!--上传头像 -->
    <HqModal :visible="avatarShow" title="设置头像" width="580" @on-cancel="cancel">
      <el-row>
        <el-col :span="12">
          <div class="upload-box">
            <div class="upload-wrap" v-if="showUpload">
              <input
                v-show="false"
                type="file"
                name="file"
                id="file"
                @change="onFileChange"
                ref="file"
              />
              <div class="upload" @click="triggerClick">
                <i class="el-icon-upload"></i>
                <span class="select-img">选择图片</span>
              </div>
            </div>
            <vue-cropper
              v-else
              ref="cropper"
              :img="options.img"
              :info="true"
              :autoCrop="options.autoCrop"
              :autoCropWidth="options.autoCropWidth"
              :autoCropHeight="options.autoCropHeight"
              :fixedBox="options.fixedBox"
              @realTime="imgChange"
            >
            </vue-cropper>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="upload-preview">
            <img class="img" :src="previews.url" :style="previews.img" v-if="previews.url"/>
            <div v-else class="img">暂无头像</div>
          </div>
          <p class="preview-text">当前头像</p>
        </el-col>
      </el-row>
      <p style="margin-top: 42px;color: #999;text-align: center">
        支持常见图片格式，png、jpg、bmp等，大小不超过10M
      </p>
      <div slot="footer">
        <el-button @click="cancel">取消</el-button>
        <el-button @click="save" type="primary">确认</el-button>
      </div>
    </HqModal>
    <!-- 修改密码 -->
    <HqModal :visible="passwordShow" title="修改密码" width="300" @on-cancel="cancel">
      <el-form ref="form" :model="form">
        <el-form-item prop="oldPassword" :rules="passwordValidate">
          <v-text-field
            v-model="form.oldPassword"
            label="原密码"
            hide-details="auto"
            outlined
            :dense="true"
            :height="32"
            :clearable="true"
          ></v-text-field>
        </el-form-item>
        <el-form-item prop="newPassword">
          <v-text-field
            v-model="form.newPassword"
            label="新密码"
            hide-details="auto"
            outlined
            :dense="true"
            :height="32"
            :clearable="true"
          ></v-text-field>
        </el-form-item>
        <el-form-item prop="newPwdAgin">
          <v-text-field
            v-model="form.newPwdAgin"
            label="确认密码"
            hide-details="auto"
            outlined
            :dense="true"
            :height="32"
            :clearable="true"
          ></v-text-field>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="cancel">取消</el-button>
        <el-button @click="savePwd" type="primary">确认</el-button>
      </div>
    </HqModal>
  </div>
</template>

<script>
import hqTitle from "@/components/hq-title";
import ValidateType from "@/utils/validate";
import { VueCropper } from "vue-cropper";
import {
  getEnterpriseList,
  reqLoginCheck,
  reqlogout
} from "api/call/tinet-api";
import {requestUserInfo} from "api/login/login-api"
import Socket from '@/utils/webSocket'
import { getsxbind, getVerCode, getdelSeatNum, getcenterList,getcenterListupda, getcenterListadd, doUserAvatar, doUpdatePassword, getLingyiUserList, getDisLingyiUser, getrplacebing } from "@/api/home";
import axios from "axios"

export default {
  name: "userCenter",
  inject: ['reload'],
  components: {
    VueCropper,
    hqTitle,
  },
  data() {
    const valideRePassword = (rule, value, callback) => {
      if (value !== this.editPasswordForm.newPass) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    return {
      bindTelnew: '',
      showreplacebing: false,
      btnshowtel: false,
      radioStatus: '1',
      bindTel: '',
      verCode: '',
      phoneTipMsg: '发送验证码',
      showzuoximodal: false,
      transferForm: {},
      LingyiList: [],
      zuoxiList: [],
      showUpload: true,
      avatarShow: false,
      passwordShow: false,
      imgError: false,
      imageUrl: "",
      file: null,
      form: {
        oldPassword: "",
        newPassword: "",
        newPwdAgin: "",
      },
      passwordValidate: {
        oldPass: [
          { required: true, message: "原密码不能为空", trigger: "blur" }
        ],
        newPass: [
          { required: true, message: "新密码不能为空", trigger: "blur" },
          { validator: ValidateType.validatePassWord }
        ],
        rePass: [{ validator: valideRePassword, trigger: "blur" }]
      },
      option: {
        img: "",
        size: 10000,
        autoCrop: true,
        autoCropWidth: "240px",
        autoCropHeight: "240px",
      },

      options: {
          img: '', //裁剪图片的地址
          info: true, //裁剪框的大小信息
          outputSize: 0.8, // 裁剪生成图片的质量
          outputType: '', // 裁剪生成图片的格式
          canScale: false, // 图片是否允许滚轮缩放
          autoCrop: true, //是否默认生成截图框
          autoCropWidth: 120, //默认生成截图框宽度
          autoCropHeight: 120,
          fixedBox: true, // 固定截图框大小 是否允许改变
          fixed: true, //是否开启截图框宽高固定比例
          fixedNumber: [1, 1], //截图框的宽高比例
          original: false, // 上传图片按照原始比例渲染
          centerBox: false, // 截图框是否被限制在图片里面
          infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        },
        previews: {},
        loading: false,
        fileList: [],
        uploadAccept: ['jpeg', 'jpg', 'png'],
        shoLingyimodal: false,
        pageParams: {
          pageSize: 10,
          pageNum: 1,
        },
        total: 0,
        pageParamszuoxi: {
          pageSize: 10,
          pageNum: 1,
          total: 0,
        },
        workerName: '',
        nickName: '',
        localre: {},
        listLoading: false,
        localrezuoxi: {},
        enterprise: '',
        callCenterConfigId: '',
        //验证码
        phoneCodeStatus: false,
        smsCodeTimer: null,
        smsSendStatus: false,
        showDisabled: false,
        hotcallcenter: '',
        callcentersource: '',
        isShowBindWX: false
    };
  },
  mounted() {
    this._getLingyiUserList()
    this.getEnterprise()
    // this.WXGetQRcode()
    console.log('this.userInfo-----------------------------', this.userInfo);
    if (this.userInfo.lingyiUserId) {
      this.transferForm.lingyiUserId = this.userInfo.lingyiUserId
    }
    if (this.userInfo.callType == 1 && this.userInfo.bindTel) {
      this.btnshowtel = true
      this.radioStatus = '2'
      this.bindTel = this.userInfo.bindTel
    } else if (this.userInfo.callType == 1 && !this.userInfo.bindTel) {
      this.btnshowtel = false
      this.radioStatus = '2'
    } else {
      this.btnshowtel = false
      this.radioStatus = "1"
    }
  },
  created() {
    if (this.$route.query.status == 0) {
      this.$message.error(this.$route.query.message)
    }else if(this.$route.query.status == 1){
      this.$message.success(this.$route.query.message)
    }
  },
  methods: {
    // 获取微信绑定信息
    WXGetQRcode() {
      axios.get(`/qw_api/wechat/qrcode/bind`, {}).then((result)=>{
        const res = result.data
        if(res.code === 0){
          const WXurl = res.result
          // 获取URL字符串中的参数部分
          var params = WXurl.split('?')[1];
          // 将参数部分按照&符号分割成数组
          var paramsArray = params.split('&');
          // 创建一个空对象来存储参数
          var paramsObj = {};
          // 遍历参数数组
          paramsArray.forEach(function(param) {
            // 将参数按照=符号分割成键值对
            var keyValue = param.split('=');
            // 将键值对存储到参数对象中
            if(keyValue[0] == "redirect_uri"){
              keyValue[1] = decodeURIComponent(keyValue[1])
            }
            paramsObj[keyValue[0]] = keyValue[1];
          });
          // 输出参数对象
          console.log(paramsObj);

          var obj = new WxLogin({
            self_redirect: false,    //默认为false(保留当前二维码)  true(当前二维码所在的地方通过iframe 内跳转到 redirect_uri)
            id: "WXlogin_container",  //容器的id
            appid: paramsObj.appid,  //应用唯一标识，在微信开放平台提交应用审核通过后获得
            scope: paramsObj.scope,   //应用授权作用域，拥有多个作用域用逗号（,）分隔，网页应用目前仅填写snsapi_login即可
            redirect_uri: paramsObj.redirect_uri,    //扫完码授权成功跳转到的路径
            state: paramsObj.state,    //用于保持请求和回调的状态，授权请求后原样带回给第三方。该参数可用于防止 csrf 攻击（跨站请求伪造攻击），建议第三方带上该参数，可设置为简单的随机数加 session 进行校验
            style: "",   //提供"black"、"white"可选，默认为黑色文字描述
            href: "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6ODAlO21hcmdpbi10b3A6MH0uaW1wb3dlckJveCAudGl0bGUge2Rpc3BsYXk6bm9uZX0=",  //自定义样式链接，第三方可根据实际需求覆盖默认样式
            stylelite:1
          });

        }else{
          this.$Message.warning(msg);
        }
      })
    },
    // 显示微信绑定二维码窗口
    showBindWX() {
      this.isShowBindWX = true
      this.WXGetQRcode()
    },
    // 更换绑定
    replacebing() {
      this.showreplacebing = true
      this.bindTelnew = ''
    },
    cancelreplace() {
      this.showreplacebing = false
      this.bindTelnew = ''
    },
    // 确认更换绑定
    savereplace() {
      let stuMobile = this.bindTelnew || "";
      let reg = /^1[0-9]{11}$/
      if(!stuMobile || stuMobile.length < 11 || reg.test(stuMobile)) {
        return this.$message.error('请输入正确的手机号')
      }
      let params = {
        bindNewTel: this.bindTelnew,
        bindTel: this.bindTel,
        callCenterConfigId: this.hotcallcenter,
        source: this.callcentersource,
        bindType: 1
      }
      getrplacebing(params).then(res => {
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.showreplacebing = false
          this.$router.go(0)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    jbBtlclick() {
      console.log('触发了解绑');
      getdelSeatNum(this.bindTel).then(res => {
        if (res.code == 0) {
          this.bindTel = ''
          this.btnshowtel = false
          this.verCode = ''
          this.phoneTipMsg = '发送验证码'
          this.$message.success(res.msg)
          this.$router.go(0)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    changeRadio(val) {
      console.log('val', val, this.userInfo.con, this.userInfo.bindTel);
      if (val == 2 && this.userInfo.con && this.userInfo.con !== undefined) {
        this.radioStatus = '1'
        this.$message.error('请先解绑SIP模式')
      } else if (val == 1 && this.userInfo.bindTel) {
        this.$message.error('请先解绑双呼模式')
        this.radioStatus = '2'
      }
    },
    // 发送验证码
    async verCodeClick() {
      // 判断手机
      let stuMobile =   this.bindTel || "";
      let reg = /^1[0-9]{11}$/
      if(!stuMobile || stuMobile.length < 11 || reg.test(stuMobile)) {
        return this.$message.error('请输入正确的手机号')
      }
      // if(stuMobile.length < 11 || !stuMobile.startsWith("1")) {
      //   return this.$message.error("手机号格式不正确")
      // }
      // this.smsSendStatus = true
      await getVerCode(stuMobile).then(res => {})
       //手机验证码状态
      this.phoneCodeStatus = true
      //多少秒之后重发
      this.smsCodeInterval = 60
      this.phoneTipMsg = this.smsCodeInterval + "秒后重发"
      this.showDisabled = true
      //定时器
      if( this.smsCodeInterval ){
         this.clearSmsTimer();
      }
      this.smsCodeTimer = setInterval(() => {
        if (this.smsCodeInterval <= 1) {
          this.initPhoneTipMsg();
          return
        }
        this.smsCodeInterval--
        this.phoneTipMsg = this.smsCodeInterval + "秒后重发"
        this.showDisabled = true
      }, 1000)
      console.log('this.showDisabled', this.showDisabled);
    },
    initPhoneTipMsg(){
      this.clearSmsTimer()
      this.phoneTipMsg = "发送验证码"
      this.phoneCodeStatus = false
      this.smsCodeInterval = 60
        this.showDisabled = false
    },
    clearSmsTimer(){
      console.log('this.smsCodeTimer', this.smsCodeTimer);
      clearInterval(this.smsCodeTimer);
      this.smsCodeTimer = null;
    },
    // 绑定双呼模式
    bindClick() {
      console.log('userInfo.con',this.userInfo.con);
      if (this.userInfo.con) {
        this.$message.error('请先解绑SIP模式')
      } else{
        let reg = /^1[0-9]{11}$/
        if(!this.bindTel || this.bindTel.length < 11 || reg.test(this.bindTel)) {
          return this.$message.error('请输入正确的手机号')
        }
        if (!this.verCode) {
          return this.$message.error('请输入验证码')
        } 
        let params = {
          bindTel: this.bindTel,
          verCode: this.verCode,
          bindType: 1,
          callCenterConfigId: this.hotcallcenter,
          source: this.callcentersource
        }
        getsxbind(params).then(res => {
          console.log('res双呼模式绑定', res);
          if (res.code == 0) {
            this.$message.success(res.msg)
            this.btnshowtel = true
            this.$router.go(0)
          } else {
            this.$message.error(res.msg)
          }
        })
      }

    },
    showzuoxijump() {
      window.open('https://www.yuque.com/docs/share/904d3937-3159-4b3a-800a-3399930f41c4?# 《IP话机配置步骤》')
    },
    zuoximodaljump() {
      window.open('https://www.yuque.com/docs/share/1b5de07c-4c26-4377-be8f-616d6c6f1a75?# 《校长解绑呼叫坐席指引》')
    },
    // 点击呼叫中心坐席
    showzuoxiClick() {
      console.log('this.bindTel', this.bindTel);
      if (this.bindTel) {
        this.$message.error('请先解绑双呼模式')
      } else {
        this.showzuoximodal = true
        this.getEnterprise()
        console.log('this.$store.state.user.loginUser', this.$store.state.user.loginUser);
        this.getcenterListpage()
      }
    },
    getcenterListpage() {
      let params = {
        pageSize: this.pageParamszuoxi.pageSize,
        pageNum: this.pageParamszuoxi.pageNum,
        workerName: this.workerName
      }
      getcenterList(params).then(res => {
        if (res.code == 0) {
          this.zuoxiList = res.result.list
          this.pageParamszuoxi.total = res.result.total
        }
      })
    },
    // 解除绑定
    async showzuoxicancel() {
      let params = {
        status: 0,
        callCenterConfigId: 0,
        userId: this.$store.state.user.loginUser.userId
      }
      console.log('params解除',params);
      
      getcenterListupda(params).then(res => {
        if (res.code == 0) {
          this.userInfo.con = ''
          this.userInfo.cnoPswd = ''
          this.getzhuxiao()
          this.$router.go(0)
          // this.$message.success(res.msg)
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    getzhuxiao() {
      reqlogout().then(res => {
          if (res.code == 0) {
            this.tinetLoginFrom = false;
            this.$message.success('退出成功')
            this.$store.commit('setTinetLoginStatus', {
              status: 'offline',
              type: 'primary',
              show: '离线'
            })
            this.reload()
          } else {
            this.$message.error(res.msg)
          }
        })
    },
    // 取消呼叫中心坐席弹窗
    cancelzuoxi() {
      this.showzuoximodal = false
    },
    // 获取呼叫中心列表
    async getEnterprise() {
      let { result, code } = await getEnterpriseList(2);
      if (code === 0) {
        console.log('result呼叫列表', result);
        // this.enterpriseList = result;
         this.hotcallcenter = result[0].id
         this.callcentersource = result[0].source
        result.map((item) => {
          if (item.name == '众投互联') {
            this.enterprise = item.id
          }
        })
      }
    },
    // 确定呼叫中心坐席弹窗
    savezuoxi() {
      console.log('this.localrezuoxi', this.localrezuoxi);
      if (this.localrezuoxi.cno) {
        let params = {
          cno: this.localrezuoxi.cno,
          id: this.localrezuoxi.id,
          callCenterConfigId: this.enterprise,
          status: 0,
          cnoPswd: this.localrezuoxi.cnoPws,
          userId: this.$store.state.user.loginUser.userId
        }
        getcenterListadd(params).then(res => {
          if (res.code == 0) {
            this.getrequestUserInfo()
            this.showzuoximodal = false
            this.localrezuoxi = {}
            this.$message.success(res.result ? res.result : res.msg)
          } else {
            this.$message.error(res.msg)
          }
        })
      } else {
        this.$message.error('请选择')
      }
    },
    // 获取用户信息
    getrequestUserInfo() {
      requestUserInfo().then(res => {
        if(res.code == 0) {
          console.log('res用户',res);
          this.userInfo.con = ''
          this.userInfo.cnoPswd = ''
         
          console.log('this.userInfo绑定前', this.userInfo);
          this.$nextTick(() => {
            this.getreqLoginCheck(res)
          })
          
          // setTimeout(() => {
          //   this.getreqLoginCheck()
          // }, 500)
        }
      })
    },
    // 登陆坐席
    getreqLoginCheck(val) {
      console.log('val坐席',val);
      this.callCenterConfigId = val.result.callCenterConfigId
      this.userInfo.con = val.result.con
        this.userInfo.cnoPswd = val.result.cnoPswd
      console.log('this.$store.state.user.loginUser', this.$store.state.user.loginUser);
       this.$nextTick(() => {
          this.$set(this.userInfo, 'con', val.result.con)
          this.$set(this.userInfo, 'cnoPswd', val.result.cnoPswd)
        })
        this.$store.commit("setLoginUser", val.result);
        console.log('this.userInfo绑定过后', this.userInfo);
      let params = {
          callCenterConfigId: this.$store.state.user.loginUser.callCenterConfigId?this.$store.state.user.loginUser.callCenterConfigId:this.callCenterConfigId,
          cno: this.$store.state.user.loginUser.con,
          source: 2
        }
        

       
        // location.reload()
      reqLoginCheck(params).then(res => {
        if(res.code == 0) {
          if(res.result.extensionStatus != 0) {
            this.socket(res.result)
          }
          // this.reload()
          this.$message.success(res.msg)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 众投连接socket
      socket(val){
        console.log('参数',val);
        let _this = this
        let Websocket = `${val.webSocketUrl}/`
        // let Websocket = val.webSocketUrl
        new Socket({
          // soket服务地址
          socketUrl: Websocket,
          extnumber: val.extension,
          // 回调data
          soketCallback: (data) => {
            console.log('data000000001111', data);
            _this.soketCallback(data)
            // _this.$store.commit('setTinetCallStatuszthl')
          }
        })
      },
       // soket回调
      soketCallback(data, url) {
        console.log('soket回调', data);
        switch (data.data.status) {
        case "idle": // 空闲
          // this.$store.commit('setTinetCallStatuszthl', 'idle')
          this.$store.commit('setTinetLoginStatus', {
            status: 'idle',
            type: 'success',
            show: '空闲'
          })
          break;
        case "offline": // 离线
          // this.$store.commit('setTinetCallStatuszthl', 'offline')
          this.$store.commit('setTinetLoginStatus', {
            status: 'offline',
            type: 'primary',
            show: '离线'
          })
          break;
        case "hangup": // 挂断
          console.log('this', this);
          // this.$store.commit('setTinetCallStatuszthl', 'hangup')
          this.$store.commit('setTinetLoginStatus', {
            status: 'hangup',
            type: 'success',
            show: '空闲'
          })
          break;
        case "answer": // 通话中
          // this.$store.commit('setTinetCallStatuszthl', 'answer')
          this.$store.commit('setTinetLoginStatus', {
            status: 'answer',
            type: 'primary',
            show: '通话中'
          })
          break;
        case "ring": // 响铃
          // this.$store.commit('setTinetCallStatuszthl', 'ring')
          this.$store.commit('setTinetLoginStatus', {
            status: 'ring',
            type: 'primary',
            show: '响铃中'
          })
          break;
        default:
          console.log('进入到回调', that.callback == 'function');

      }
      //  this.reload()
      },
    // 查询呼叫中心坐席
    searchzuoxi() {
      this.pageParamszuoxi.pageNum = 1
      this.pageParamszuoxi.pageSize = 10
      this.getcenterListpage()
    },
    resetFnzuoxi() {
      this.workerName = ''
      this.pageParamszuoxi.pageNum = 1
      this.pageParamszuoxi.pageSize = 10
      this.getcenterListpage()
    },
    // 点击呼叫中心坐席表格
    clickRowzuoxi(row) {
      this.localrezuoxi = row
    },
    sizeChangezuoxi(val) {
      this.pageParamszuoxi.pageSize = val
      this.getcenterListpage()
    },
    currentChangezuoxi(val) {
      this.pageParamszuoxi.pageNum = val
      this.getcenterListpage()
    },
    // 零一列表-----------------------------------------
     searchUsernc() {
      this.pageParams.pageNum = 1
      this._getLingyiUserList()
    },
    resetFn() {
      this.nickName = ''
      this.pageParams.pageNum = 1
      this._getLingyiUserList()
    },
    clickRownc(val) {
      this.localre = val
      console.log('this.localre', this.localre);
    },
    currentChange(val) {
       this.pageParams.pageNum = val
      this._getLingyiUserList()
    },
    sizeChange(val) {
      this.pageParams.pageSize = val
      this._getLingyiUserList()
    },
    // 获取零一裂变用户
    async _getLingyiUserList() {
      const { result, msg } = await getLingyiUserList(this.pageParams, this.nickName?this.nickName:'')
      console.log('data零一裂变', msg, result);
      this.LingyiList = result.list
      this.total = result.totalCount
    },
    showBInglingyi() {
      this.shoLingyimodal = true
      console.log('this.shoLingyimodal',this.shoLingyimodal);
    },
    cancellingyi() {
      this.shoLingyimodal = false
    },
    savelingyi() {
      console.log(this.transferForm.lingyiUserId);
      this.transferForm.lingyiUserId = this.localre.userId
      getDisLingyiUser(this.transferForm.lingyiUserId !== undefined ? this.transferForm.lingyiUserId : '').then(res=> {
        if(res.code == 0) {
          this.$message.success(res.msg)
          this.shoLingyimodal = false
          this.$store.dispatch("getUserInfo")
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    openAvatar() {
      this.avatarShow = true
      this.showUpload = true
      this.previews.url = this.$store.state.user.loginUser.avatar
    },
    triggerClick() {
      this.$refs.file.click();
    },
    onImgError() {
      this.imgError = true;
    },
    // 保存
    async save() {
      if(!this.$refs.cropper) {
        this.$message.error('请选择图片！')  
        return false
      }
      this.$refs.cropper.getCropBlob(async blob => {
        let formData = new FormData()
        formData.append("file", blob)
        const { code, msg } = await await doUserAvatar(formData)
        if(code === 0) {
          this.$message.success(msg) 
          this.$store.dispatch("getUserInfo");
          this.avatarShow = false;
          // 更新用户信息的接口
          this.$store.dispatch("getUserInfo")
        }
      })
    },
    // 取消
    cancel() {
      this.form = {
        originPwd: "",
        newPwd: "",
        newPwdAgin: "",
      };
      this.previews = {}
      this.passwordShow = false;
      this.avatarShow = false;
    },
    async savePwd() {
      this.$refs.form.validate();
      const { code, msg } = await doUpdatePassword(this.form);
      if (code === 0) {
        this.$message.success(msg);
        this.passwordShow = false;
      }else{
        this.$message.warning(msg)
      }
    },
    imgChange(data) {
      this.previews = data;
    },
    onFileChange(event) {
      this.showUpload = false;
      const file = event.target.files[0];
      const filename = file.name;
      const fileExtension = filename.substring(filename.lastIndexOf(".") + 1);
      if (
        fileExtension !== "png" &&
        fileExtension !== "jpg" &&
        fileExtension !== "jpeg"
      ) {
        this.$message.info("文件格式不符！");
        return false;
      }
      const fileSizeB = file.size;
      const fileSizeK = Math.round((fileSizeB / 1024) * 100) / 100;
      //图片大小限制
      if (/image\/\w+/.test(file.type)) {
        if (fileSizeK >= 10240) {
          this.$message.info("文件大小不能超过10M！");
          return false;
        }
      }
      this.file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          this.imageUrl = img.src;
          this.options.img = img.src;
        };
      };
    },
  },
  computed: {
    userInfo() {
      return this.$store.state.user.loginUser;
    },
  },
};
</script>

<style lang="scss" scoped>
#WXlogin_container{
  overflow: hidden;
  height: 200px;
  text-align: center;
}
::v-deep .el-popconfirm__main {
  margin-top: 25px;
  margin-bottom: 15px;
}
::v-deep .el-popper {
  z-index: 999999 !important;
}
::v-deep .el-select-dropdown {
  z-index: 999999 !important;
}
.confirm_box {
  width: 930px !important;
}
.searchBtn {
  margin-top: 4px;
  margin-left: 4px;
}
.selectImg {
  width: 40px;
  height: 40px;
  display: inline-block;
  vertical-align:middle;
  border-radius: 50%;
}
.lingyiAvatar {
  width: 40px;
  height: 40px;
  // background-color: red;
  border-radius: 50%;
  border: none;
  display: inline-block;
  > img {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 50%;
    vertical-align:middle;
  }
}
.user-center {
  width: 1206px;
  min-height: 870px;  
  margin: 0 auto;
  padding-top: 18px;
  background-color: #fff;
  &-body {
    padding-left: 48px;

    .avatar-text {
      line-height: 100px;
    }

    .avatar-wrap {
      position: relative;
      width: 102px;
      height: 102px;
      cursor: pointer;
      .avatar {
        position: absolute;
        left: 0;
        top: 0;
        height: 102px;
        border-radius: 50%;
        z-index: 2;

        > img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }

        >div {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          line-height: 102px;
          text-align: center;
          color: #999;

        }
      }
      > i {
        position: absolute;
        border: 2px solid #fff;
        border-radius: 50%;
        font-size: 14px;
        right: 0;
        bottom: 0;
        color: #337aff;
        z-index: 10;
      }
    }
  }
}

.upload {
      height: 186px;
      width: 186px;
      border: 1px solid #e5e9ef;
      text-align: center;

      >i {
        font-size: 70px;
        color: #999;
        margin-top: 30px;
      }

      .select-img {
        display: block;
      }
    }

    .upload-wrap {
      p {
        color: #999;
      }
    }
    .cut-wrap {
      position: relative;
      width: 120px;
      height: 120px;
      .avatar {
        width: 100%;
        height: 100%;
      }
      .cut-mask {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba($color: #000000, $alpha: 0.7);
        z-index: 1;
      }
      .cut-circle {
        position: absolute;
        width: 120px;
        height: 120px;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border: 2px solid #337aff;
        border-radius: 50%;
        z-index: 55;
      }
    #file {
      display: none;
    }

.upload-box {
  display: flex;
  justify-content: space-between;

  .modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    }
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .img-box {
    width: 178px;
    height: 178px;
    border: 1px solid #e5e9ef;

    img {
      width: 100%;
      height: 100%;
    }

    > div {
      line-height: 178px;
      text-align: center;
    }
  }
}

.v-text-field.v-text-field--enclosed {
  margin-bottom: 12px;
  margin-top: 6px;
}


.btnS{
  display: flex;
  padding-top: 20px;
  .i{
    position: relative;
    font-size: 12px;
  }
}
.upload-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 186px;
  width: 186px;
  margin: 0 auto;
  background: #E5E9EF;
}
.upload-preview {
  // -webkit-box-sizing: content-box;
  // -moz-box-sizing: content-box;
  // box-sizing: content-box;
  // position: absolute;
  // top: 50%;
  // transform: translate(50%, -50%);
  // width: 120px;
  // height: 120px;
  // border-radius: 50%;
  // box-shadow: 0 0 4px #eaeaea;
  // overflow: hidden;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin: 18px auto;
  overflow: hidden;
  border: 1px solid #E5E9EF;
  line-height: 120px;
  text-align: center;

  &::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 186px;
    background: #E5E9EF;
    top: 0;
    left: 280px;
  }
  .img {
    width: 100%;
    height: 100%;
  }
}
.preview-text {
  font-size: 14px;
  color: #999;
  font-weight: 400;
  text-align: center;
}
</style>

