var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { background: "#f5f5fa" } },
    [
      _c(
        "div",
        { staticClass: "user-center" },
        [
          _c("hq-title", [_vm._v("个人信息")]),
          _c(
            "div",
            { staticClass: "user-center-body" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("p", { staticClass: "filed avatar-text" }, [
                      _vm._v("头像：")
                    ])
                  ]),
                  _c("el-col", { attrs: { span: 18 } }, [
                    _c("div", { staticClass: "avatar-wrap" }, [
                      _c(
                        "div",
                        {
                          staticClass: "avatar",
                          on: { click: _vm.openAvatar }
                        },
                        [
                          _vm.userInfo.avatar
                            ? _c("img", {
                                attrs: {
                                  src: _vm.userInfo.avatar,
                                  alt: "选择头像"
                                },
                                on: { error: _vm.onImgError }
                              })
                            : _c("div", [_vm._v("点击上传头像")])
                        ]
                      ),
                      _c("i", { staticClass: "iconfont iconCamerafill" })
                    ])
                  ])
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("p", { staticClass: "filed" }, [_vm._v("用户姓名：")])
                  ]),
                  _c("el-col", { attrs: { span: 18 } }, [
                    _c("p", [_vm._v(_vm._s(_vm.userInfo.userName))])
                  ])
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("p", { staticClass: "filed" }, [_vm._v("用户手机：")])
                  ]),
                  _c("el-col", { attrs: { span: 18 } }, [
                    _c("p", [_vm._v(_vm._s(_vm.userInfo.phone))])
                  ])
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("p", { staticClass: "filed" }, [_vm._v("组织机构：")])
                  ]),
                  _c("el-col", { attrs: { span: 18 } }, [
                    _c("p", [_vm._v(_vm._s(_vm.userInfo.deptName))])
                  ])
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("p", { staticClass: "filed" }, [_vm._v("角色：")])
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    _vm._l(_vm.userInfo.roleList, function(item, index) {
                      return _c(
                        "span",
                        { staticStyle: { "margin-right": "10px" } },
                        [_vm._v(" " + _vm._s(item.roleName) + " ")]
                      )
                    }),
                    0
                  )
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("p", { staticClass: "filed" }, [_vm._v("登录密码：")])
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              _vm.passwordShow = true
                            }
                          }
                        },
                        [_vm._v("修改密码")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("p", { staticClass: "filed" }, [_vm._v("零一裂变：")])
                  ]),
                  _c("el-col", { attrs: { span: 18 } }, [
                    _c("span", { staticClass: "lingyiAvatar" }, [
                      _c("img", {
                        attrs: { src: _vm.userInfo.lingyiAvatar, alt: "" }
                      })
                    ]),
                    _vm._v("  "),
                    _c("span", [_vm._v(_vm._s(_vm.userInfo.lingyiUserName))]),
                    _vm._v("    "),
                    _c(
                      "span",
                      {
                        staticStyle: { cursor: "pointer", color: "#ddaa00" },
                        on: { click: _vm.showBInglingyi }
                      },
                      [_vm._v("去绑定")]
                    )
                  ])
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("p", { staticClass: "filed" }, [_vm._v("零一裂变ID：")])
                  ]),
                  _c("el-col", { attrs: { span: 18 } }, [
                    _c("p", [_vm._v(_vm._s(_vm.userInfo.lingyiUserId))])
                  ])
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("p", { staticClass: "filed" }, [
                      _vm._v("呼叫中心坐席：")
                    ])
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          on: {
                            change: function($event) {
                              return _vm.changeRadio(1)
                            }
                          },
                          model: {
                            value: _vm.radioStatus,
                            callback: function($$v) {
                              _vm.radioStatus = $$v
                            },
                            expression: "radioStatus"
                          }
                        },
                        [_vm._v("SIP模式")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "2" },
                          on: {
                            change: function($event) {
                              return _vm.changeRadio(2)
                            }
                          },
                          model: {
                            value: _vm.radioStatus,
                            callback: function($$v) {
                              _vm.radioStatus = $$v
                            },
                            expression: "radioStatus"
                          }
                        },
                        [_vm._v("双呼模式")]
                      )
                    ],
                    1
                  ),
                  _vm.radioStatus == "1"
                    ? _c(
                        "el-col",
                        {
                          staticStyle: { marginTop: "20px" },
                          attrs: { span: 18 }
                        },
                        [
                          _c(
                            "p",
                            { staticStyle: { display: "inline-block" } },
                            [
                              _vm._v(
                                _vm._s(_vm.userInfo.con) +
                                  _vm._s(_vm.userInfo.con ? "/" : "") +
                                  _vm._s(_vm.userInfo.cnoPswd)
                              )
                            ]
                          ),
                          _vm._v("    "),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                cursor: "pointer",
                                color: "#ddaa00"
                              },
                              on: { click: _vm.showzuoxiClick }
                            },
                            [_vm._v("去绑定")]
                          ),
                          _vm._v("    "),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                cursor: "pointer",
                                color: "#ddaa00"
                              },
                              on: { click: _vm.showzuoxicancel }
                            },
                            [_vm._v("解绑")]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.radioStatus == "1"
                    ? _c("el-col", { attrs: { span: 18 } }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              cursor: "pointer",
                              color: "#337AFF"
                            },
                            on: { click: _vm.showzuoxijump }
                          },
                          [_vm._v("IP话机设置方法")]
                        )
                      ])
                    : _vm._e(),
                  _vm.radioStatus == "2" && !_vm.btnshowtel
                    ? _c(
                        "el-col",
                        {
                          staticStyle: { marginTop: "20px" },
                          attrs: { span: 18 }
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 13 } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "手机号",
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: true,
                                  height: 32
                                },
                                model: {
                                  value: _vm.bindTel,
                                  callback: function($$v) {
                                    _vm.bindTel = $$v
                                  },
                                  expression: "bindTel"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.radioStatus == "2" && !_vm.btnshowtel
                    ? _c(
                        "el-col",
                        {
                          staticStyle: { paddingLeft: "156px" },
                          attrs: { span: 10 }
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "验证码",
                                  "hide-details": "auto",
                                  outlined: "",
                                  dense: true,
                                  height: 32
                                },
                                model: {
                                  value: _vm.verCode,
                                  callback: function($$v) {
                                    _vm.verCode = $$v
                                  },
                                  expression: "verCode"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.radioStatus == "2" && !_vm.btnshowtel
                    ? _c(
                        "el-col",
                        {
                          staticStyle: { marginTop: "4px" },
                          attrs: { span: 2 }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                disabled: _vm.showDisabled
                              },
                              on: { click: _vm.verCodeClick }
                            },
                            [_vm._v(_vm._s(_vm.phoneTipMsg))]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.radioStatus == "2" && !_vm.btnshowtel
                    ? _c(
                        "el-col",
                        {
                          staticStyle: {
                            marginTop: "10px",
                            paddingLeft: "156px"
                          },
                          attrs: { span: 18 }
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 3 } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.bindClick }
                                },
                                [_vm._v("绑定")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.radioStatus == "2" && _vm.btnshowtel
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            position: "absolute",
                            left: "156px",
                            top: "42px"
                          }
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.bindTel) + "      "),
                          _c(
                            "el-popconfirm",
                            {
                              attrs: {
                                "confirm-button-text": "确定",
                                "cancel-button-text": "取消",
                                icon: "el-icon-info",
                                "icon-color": "red",
                                title: "您确定解绑吗?"
                              },
                              on: { confirm: _vm.jbBtlclick }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    cursor: "pointer",
                                    color: "#ddaa00"
                                  },
                                  attrs: { slot: "reference" },
                                  slot: "reference"
                                },
                                [_vm._v("解绑")]
                              )
                            ]
                          ),
                          _vm._v("      "),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                cursor: "pointer",
                                color: "#ddaa00"
                              },
                              on: { click: _vm.replacebing }
                            },
                            [_vm._v("更换绑定")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticStyle: { "margin-top": "30px" },
                  attrs: { gutter: 20 }
                },
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("p", { staticClass: "filed" }, [_vm._v("微信绑定：")])
                  ]),
                  _c("el-col", { attrs: { span: 18 } }, [
                    _vm.userInfo.hasWechat
                      ? _c("span", [_vm._v("已绑定")])
                      : _vm._e(),
                    !_vm.userInfo.hasWechat
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              cursor: "pointer",
                              color: "#ddaa00"
                            },
                            on: { click: _vm.showBindWX }
                          },
                          [_vm._v("去绑定")]
                        )
                      : _vm._e()
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "HqModal",
        {
          attrs: {
            visible: _vm.isShowBindWX,
            title: "扫码绑定微信",
            width: "830"
          },
          on: {
            "on-cancel": function($event) {
              _vm.isShowBindWX = false
            }
          }
        },
        [
          _c("div", { attrs: { id: "WXlogin_container" } }),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.isShowBindWX = false
                    }
                  }
                },
                [_vm._v("关闭")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "HqModal",
        {
          attrs: {
            visible: _vm.showreplacebing,
            title: "更换绑定",
            width: "830"
          },
          on: { "on-cancel": _vm.cancelreplace }
        },
        [
          _c(
            "el-row",
            { staticStyle: { width: "94%", "padding-left": "24px" } },
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c("span", { staticStyle: { MarginBottom: "10px" } }, [
                  _vm._v(" " + _vm._s(_vm.bindTel) + " ")
                ])
              ]),
              _c("br"),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c("v-text-field", {
                    staticStyle: { "padding-top": "10px" },
                    attrs: {
                      label: "请输入新手机号",
                      "hide-details": "auto",
                      outlined: "",
                      dense: true,
                      height: 32
                    },
                    model: {
                      value: _vm.bindTelnew,
                      callback: function($$v) {
                        _vm.bindTelnew = $$v
                      },
                      expression: "bindTelnew"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.cancelreplace } }, [
                _vm._v("取消")
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.savereplace } },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "HqModal",
        {
          attrs: {
            visible: _vm.showzuoximodal,
            title: "绑定呼叫坐席",
            width: "830"
          },
          on: { "on-cancel": _vm.cancelzuoxi }
        },
        [
          _c(
            "el-row",
            { staticStyle: { width: "94%", "padding-left": "24px" } },
            [
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "姓名",
                      "hide-details": "auto",
                      outlined: "",
                      dense: true,
                      height: 32
                    },
                    model: {
                      value: _vm.workerName,
                      callback: function($$v) {
                        _vm.workerName = $$v
                      },
                      expression: "workerName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "searchBtn",
                      on: { click: _vm.searchzuoxi }
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "searchBtn",
                      on: { click: _vm.resetFnzuoxi }
                    },
                    [_vm._v("重置")]
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "margin-left": "20px",
                        cursor: "pointer",
                        color: "#337AFF"
                      },
                      on: { click: _vm.zuoximodaljump }
                    },
                    [_vm._v("校长解绑呼叫坐席指引")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            { staticStyle: { "margin-left": "20px", color: "#337AFF" } },
            [
              _vm._v(
                "呼叫坐席由校长或者省区负责人与呼叫中心购买所得，如校区有购买呼叫坐席，请联系校长索取坐席信息"
              )
            ]
          ),
          _c(
            "el-row",
            { staticStyle: { width: "94%", margin: "0 auto" } },
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading"
                        }
                      ],
                      ref: "multipleTable",
                      staticStyle: { width: "100%" },
                      attrs: {
                        "highlight-current-row": "",
                        data: _vm.zuoxiList,
                        "tooltip-effect": "dark",
                        border: "",
                        stripe: "",
                        size: "mini",
                        "element-loading-text": "拼命加载中",
                        "row-style": { height: "40px" },
                        "cell-style": { padding: "0px" },
                        "header-cell-style": { background: "#e5f2ff" }
                      },
                      on: { "row-click": _vm.clickRowzuoxi }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "name", label: "姓名", align: "center" }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "workerAccount",
                          label: "账号",
                          align: "center"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "cno", label: "分机号", align: "center" }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "deptName",
                          label: "部门",
                          align: "center"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "page_test_bottom" },
                    [
                      _c("el-pagination", {
                        attrs: {
                          background: "",
                          layout: "total, sizes, prev, pager, next, jumper",
                          "current-page": _vm.pageParamszuoxi.pageNum,
                          "page-sizes": [10, 20, 50, 100],
                          "page-size": _vm.pageParamszuoxi.pageSize,
                          total: _vm.pageParamszuoxi.total
                        },
                        on: {
                          "size-change": _vm.sizeChangezuoxi,
                          "current-change": _vm.currentChangezuoxi
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.cancelzuoxi } }, [
                _vm._v("取消")
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.savezuoxi } },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "HqModal",
        {
          attrs: {
            visible: _vm.shoLingyimodal,
            title: "绑定零一裂变用户",
            width: "830"
          },
          on: { "on-cancel": _vm.cancellingyi }
        },
        [
          _c(
            "el-row",
            { staticStyle: { width: "94%", "padding-left": "24px" } },
            [
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "用户名",
                      "hide-details": "auto",
                      outlined: "",
                      dense: true,
                      height: 32
                    },
                    model: {
                      value: _vm.nickName,
                      callback: function($$v) {
                        _vm.nickName = $$v
                      },
                      expression: "nickName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "searchBtn",
                      on: { click: _vm.searchUsernc }
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    { staticClass: "searchBtn", on: { click: _vm.resetFn } },
                    [_vm._v("重置")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { width: "94%", margin: "0 auto" } },
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading"
                        }
                      ],
                      ref: "multipleTable",
                      staticStyle: { width: "100%" },
                      attrs: {
                        "highlight-current-row": "",
                        data: _vm.LingyiList,
                        "tooltip-effect": "dark",
                        border: "",
                        stripe: "",
                        size: "mini",
                        "element-loading-text": "拼命加载中",
                        "row-style": { height: "20px" },
                        "cell-style": { padding: "0px" },
                        "header-cell-style": { background: "#e5f2ff" }
                      },
                      on: { "row-click": _vm.clickRownc }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "avatar",
                          label: "头像",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("img", {
                                  staticClass: "selectImg",
                                  attrs: { src: scope.row.avatar, alt: "" }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "nickName",
                          label: "用户名",
                          align: "center"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "page_test_bottom" },
                    [
                      _c("el-pagination", {
                        attrs: {
                          background: "",
                          layout: "total, sizes, prev, pager, next, jumper",
                          "current-page": _vm.pageParams.pageNum,
                          "page-sizes": [10, 20, 50, 100],
                          "page-size": _vm.pageParams.pageSize,
                          total: _vm.total
                        },
                        on: {
                          "size-change": _vm.sizeChange,
                          "current-change": _vm.currentChange
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.cancellingyi } }, [
                _vm._v("取消")
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.savelingyi } },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "HqModal",
        {
          attrs: { visible: _vm.avatarShow, title: "设置头像", width: "580" },
          on: { "on-cancel": _vm.cancel }
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 12 } }, [
                _c(
                  "div",
                  { staticClass: "upload-box" },
                  [
                    _vm.showUpload
                      ? _c("div", { staticClass: "upload-wrap" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false"
                              }
                            ],
                            ref: "file",
                            attrs: { type: "file", name: "file", id: "file" },
                            on: { change: _vm.onFileChange }
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "upload",
                              on: { click: _vm.triggerClick }
                            },
                            [
                              _c("i", { staticClass: "el-icon-upload" }),
                              _c("span", { staticClass: "select-img" }, [
                                _vm._v("选择图片")
                              ])
                            ]
                          )
                        ])
                      : _c("vue-cropper", {
                          ref: "cropper",
                          attrs: {
                            img: _vm.options.img,
                            info: true,
                            autoCrop: _vm.options.autoCrop,
                            autoCropWidth: _vm.options.autoCropWidth,
                            autoCropHeight: _vm.options.autoCropHeight,
                            fixedBox: _vm.options.fixedBox
                          },
                          on: { realTime: _vm.imgChange }
                        })
                  ],
                  1
                )
              ]),
              _c("el-col", { attrs: { span: 12 } }, [
                _c("div", { staticClass: "upload-preview" }, [
                  _vm.previews.url
                    ? _c("img", {
                        staticClass: "img",
                        style: _vm.previews.img,
                        attrs: { src: _vm.previews.url }
                      })
                    : _c("div", { staticClass: "img" }, [_vm._v("暂无头像")])
                ]),
                _c("p", { staticClass: "preview-text" }, [_vm._v("当前头像")])
              ])
            ],
            1
          ),
          _c(
            "p",
            {
              staticStyle: {
                "margin-top": "42px",
                color: "#999",
                "text-align": "center"
              }
            },
            [_vm._v(" 支持常见图片格式，png、jpg、bmp等，大小不超过10M ")]
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "HqModal",
        {
          attrs: { visible: _vm.passwordShow, title: "修改密码", width: "300" },
          on: { "on-cancel": _vm.cancel }
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "oldPassword", rules: _vm.passwordValidate } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "原密码",
                      "hide-details": "auto",
                      outlined: "",
                      dense: true,
                      height: 32,
                      clearable: true
                    },
                    model: {
                      value: _vm.form.oldPassword,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "oldPassword", $$v)
                      },
                      expression: "form.oldPassword"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "newPassword" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "新密码",
                      "hide-details": "auto",
                      outlined: "",
                      dense: true,
                      height: 32,
                      clearable: true
                    },
                    model: {
                      value: _vm.form.newPassword,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "newPassword", $$v)
                      },
                      expression: "form.newPassword"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "newPwdAgin" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "确认密码",
                      "hide-details": "auto",
                      outlined: "",
                      dense: true,
                      height: 32,
                      clearable: true
                    },
                    model: {
                      value: _vm.form.newPwdAgin,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "newPwdAgin", $$v)
                      },
                      expression: "form.newPwdAgin"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.savePwd } },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }